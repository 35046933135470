import * as React from 'react';
import { SignupForm } from '../components/SignupForm';

export function Signup() {
  return (
    <>
      <h1>Vet Portal Signup</h1>
      <SignupForm />
    </>
  );
}
