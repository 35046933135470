import * as React from 'react';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import type { CognitoUserAmplify } from '@aws-amplify/ui';

export type AuthenticatorProps = Parameters<typeof Authenticator>[0];

export type SignOutCallback = ReturnType<typeof useAuthenticator>['signOut'];
export type AuthenticatorState = {
  signOut: SignOutCallback;
  user: CognitoUserAmplify;
};

export interface AuthorizedRouteProps {
  children: AuthenticatorProps['children'];
}

export function AuthorizedRoute({ children }: AuthorizedRouteProps) {
  return (
    <div>
      <h6>Fake Creds</h6>
      <pre>
        username: nmyers@thefarmersdog.com
        <br />
        password: SecureTest4!
      </pre>
      <Authenticator hideSignUp={true}>{children}</Authenticator>
    </div>
  );
}
