import React from 'react';
import { useHistory } from 'react-router';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { signupFormValidationSchema } from './signupFormValidationSchema';
import { PrivacyPolicy, privacyPolicyText } from './PrivacyPolicy';
import { SignupFormFields } from '../../constants/types';
import { signupFormFieldsData, FieldType } from './constants';
// import { useCreateVet } from '../graphql/queries/useCreateVet';

export function SignupForm() {
  // const [createVetQuery, createVetQueryState] = useCreateVet();

  const history = useHistory();

  // TODO - *remove later; makes testing form easier
  const initialFormValues = {
    [SignupFormFields.firstName]: 'Code-E',
    [SignupFormFields.lastName]: 'Clegginator',
    [SignupFormFields.position]: 'bvm',
    [SignupFormFields.phone]: '2223334444',
    [SignupFormFields.email]: 'imavet@vet.com',
    [SignupFormFields.hospitalName]: 'Great Vet',
    [SignupFormFields.hospitalCity]: 'Atlanta',
    [SignupFormFields.hospitalState]: 'Georgia',
    [SignupFormFields.howDidYouHearAboutUs]: 'I work here',
    [SignupFormFields.acceptedPrivacyPolicy]: false,
  };

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        validationSchema={signupFormValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            // add the privacy policy text before submission since this doesn't come directly from a Field component
            values[SignupFormFields.privacyPolicyText] = privacyPolicyText;

            // TODO - remove promise; using as placeholder for async
            await new Promise(resolve => setTimeout(resolve, 500));
            alert(JSON.stringify(values, null, 2));

            // TODO - add graphql
            // if (!(createVetQueryState.called || createVetQueryState.loading)) {
            //   await createVet({
            //     variables: {
            //       email: values.email,
            //       privacyPolicyText,
            //     },
            //   });
            // }
          } catch (error) {
            throw new Error('An unknown error has occurred.');
          }

          setSubmitting(false);
          history.push('/app');
        }}
      >
        <Form
          // TODO - remove style when we have frontend design; just don't want it to be a complete eyesore :)
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '400px',
            margin: '50px',
          }}
        >
          {signupFormFieldsData.map((field, i) => {
            return (
              <>
                {field.type === FieldType.Select ? (
                  <Field
                    component="select"
                    key={`field-${i}`}
                    name={field.name}
                    label={field.label ?? field.name}
                  >
                    {field.options.map((option, j) => {
                      return (
                        <option key={`${option}-${j}`} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </Field>
                ) : (
                  <Field
                    key={`field-${i}`}
                    name={field.name}
                    label={field.label ?? field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                  />
                )}
                <ErrorMessage key={`error-${i}`} name={field.name} />
              </>
            );
          })}
          <PrivacyPolicy />
          <button type="submit">Submit</button>
        </Form>
      </Formik>
    </>
  );
}
