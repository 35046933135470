import { SignupFormFields, Position } from '../../../constants/types';
import { states } from './statesStaticData';

export enum FieldType {
  Text = 'text',
  Select = 'select',
}

interface FormField {
  name: string;
  label?: string;
}

interface InputFormField extends FormField {
  type: FieldType.Text;
  placeholder?: string;
}

interface SelectFormField extends FormField {
  type: FieldType.Select;
  options: string[];
}

export const signupFormFieldsData: (InputFormField | SelectFormField)[] = [
  {
    name: SignupFormFields.firstName,
    type: FieldType.Text,
    placeholder: 'First Name',
  },
  {
    name: SignupFormFields.lastName,
    type: FieldType.Text,
    placeholder: 'Last Name',
  },
  {
    name: SignupFormFields.position,
    type: FieldType.Select,
    options: Object.values(Position),
  },
  {
    name: SignupFormFields.phone,
    type: FieldType.Text,
    placeholder: 'Phone Number',
  },
  {
    name: SignupFormFields.email,
    type: FieldType.Text,
    placeholder: 'Email Address',
  },
  {
    name: SignupFormFields.hospitalName,
    type: FieldType.Text,
    placeholder: 'Hospital Name',
  },
  {
    name: SignupFormFields.hospitalCity,
    type: FieldType.Text,
    placeholder: 'Hospital City',
  },
  {
    name: SignupFormFields.hospitalState,
    type: FieldType.Select,
    options: states,
  },
  {
    name: SignupFormFields.howDidYouHearAboutUs,
    type: FieldType.Text,
    placeholder: 'How Did You Hear About Us?',
  },
];
