import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AmplifyProvider } from '@aws-amplify/ui-react';

import { Account, Signup, Home, Login } from './pages';
import { AuthorizedRoute } from './components';

import {
  PATH_HOME,
  PATH_REGISTER,
  PATH_LOGIN,
} from '../tools/constants/routes';

export function Application() {
  return (
    <AmplifyProvider>
      <Switch>
        <Route path="/app">
          <AuthorizedRoute>
            {authenticatorProps => <Account {...authenticatorProps} />}
          </AuthorizedRoute>
        </Route>
        <Route path={PATH_REGISTER}>
          <Signup />
        </Route>
        <Route exact path={PATH_HOME}>
          <Home />
        </Route>
        <Route path={PATH_LOGIN}>
          <Login />
        </Route>
        <Route path="*">
          <Redirect to={PATH_HOME} />
        </Route>
      </Switch>
    </AmplifyProvider>
  );
}
