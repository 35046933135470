import React from 'react';
import ReactDOM from 'react-dom';

import { ClientApp } from './ClientApp';
import { Amplify } from 'aws-amplify';

import { polyfillSmoothScroll } from '@farmersdog/corgi';

const render = () =>
  ReactDOM.hydrate(<ClientApp />, document.getElementById('root'));

function setupAmplify() {
  // https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#authentication-amazon-cognito
  Amplify.configure({
    aws_project_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_urcSsLCoF',
    aws_user_pools_web_client_id: '2n0lo71mku1iotpki3mjtp1q32',
  });
}

setupAmplify();
polyfillSmoothScroll();
render();
