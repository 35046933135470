import * as yup from 'yup';
import { SignupFormFields, Position } from '../../constants/types';

// TODO - finish setting all desired validations forms
export const signupFormValidationSchema = yup.object({
  [SignupFormFields.firstName]: yup.string().required('Required'),
  [SignupFormFields.lastName]: yup.string().required('Required'),
  [SignupFormFields.position]: yup
    .string()
    .oneOf(Object.values(Position))
    .required('Required'),
  [SignupFormFields.phone]: yup.string().required('Required'),
  [SignupFormFields.email]: yup
    .string()
    .email('Invalid email address')
    .required('Required'),
  [SignupFormFields.hospitalName]: yup.string().required('Required'),
  [SignupFormFields.hospitalCity]: yup.string().required('Required'),
  // TODO - do we want to put constraints around state selection?
  [SignupFormFields.hospitalState]: yup.string().required('Required'),
  [SignupFormFields.howDidYouHearAboutUs]: yup.string().required('Required'),
  // TODO - do we want to enforce accepting policy to sign up?
  [SignupFormFields.acceptedPrivacyPolicy]: yup
    .boolean()
    .required('Required')
    .oneOf([true], 'You must accept the terms and conditions.'),
});
