import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import 'normalize.css';

/**
 * This App import should be kept as the first import in order to avoid css
 * source ordering issues.
 */
import { Application } from './Application';

export function ClientApp() {
  return (
    <BrowserRouter>
      <Application />
    </BrowserRouter>
  );
}
