import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { SignupFormFields } from '../../constants/types';

// TODO - replace with actual text
export const privacyPolicyText = '*Privacy Policy Text Placeholder*';

export function PrivacyPolicy() {
  return (
    <div>
      <p>
        <Field type="checkbox" name={SignupFormFields.acceptedPrivacyPolicy} />
        {' Accept Privacy Policy'}
      </p>
      {privacyPolicyText}
      <ErrorMessage name={SignupFormFields.acceptedPrivacyPolicy} />
    </div>
  );
}
