export enum SignupFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  position = 'postion',
  phone = 'phone',
  email = 'email',
  howDidYouHearAboutUs = 'howDidYouHearAboutUs',
  acceptedPrivacyPolicy = 'acceptedPrivacyPolicy',
  privacyPolicyText = 'privacyPolicyText',
  hospitalName = 'hospitalName',
  hospitalCity = 'hospitalCity',
  hospitalState = 'hospitalState',
}

export type Vet = {
  id: string;
  firstName: string;
  lastName: string;
  position: Position;
  phone: string;
  email: string;
  howDidYouHearAboutUs: string;
  acceptedPrivacyPolicy: boolean;
  privacyPolicyText: string;
  hospital: Hospital;
};

export type Hospital = {
  city: string;
  name: string;
  state: string;
};

// TODO - make values display as plain english: https://app.shortcut.com/farmersdog/story/89248/align-vet-portal-position-enum-values-to-be-human-readable
export enum Position {
  DVM = 'dvm',
  VMD = 'vmd',
  BVM = 'bvm',
  RVT = 'rvt',
  LVT = 'lvt',
  CVT = 'cvt',
  CVPM = 'cvpm',
  PracticeManager = 'practice_manager',
  VetStudent = 'vet_student',
  VetAssistant = 'vet_assistant',
  CSR = 'csr',
  Receptionist = 'receptionist',
  DoctorsAssistant = 'doctors_assistant',
  Administrative = 'administrative',
  Other = 'other',
}
